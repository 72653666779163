import Image from "next/image";
import React from "react";
import { useInView } from "react-intersection-observer";

function Products({ array }) {
  const { ref: headerTitle1, inView: isVisible1 } = useInView({
    rootMargin: "-50px",
    delay: 100,
    triggerOnce: true,
  });
  const { ref: headerTitle2, inView: isVisible2 } = useInView({
    rootMargin: "-100px",
    delay: 200,
    triggerOnce: true,
  });
  const { ref: headerTitle3, inView: isVisible3 } = useInView({
    rootMargin: "-150px",
    delay: 300,
    triggerOnce: true,
  });

  return (
    <>
      <div
        ref={headerTitle1}
        className={`product-container-${array[0].className} ${
          isVisible1 ? "animate" : ""
        }`}
      >
        <Image src={array[0].image} placeholder = {"blur"} alt={array[0].title} fill />
        <div className="product-name-container">
          <p>{array[0].title}</p>
        </div>
      </div>
      <div
        ref={headerTitle2}
        className={`product-container-${array[1].className} ${
          isVisible2 ? "animate" : ""
        }`}
      >
        <Image src={array[1].image} alt={array[1].title} placeholder = {"blur"} fill />
        <div className="product-name-container">
          <p>{array[1].title}</p>
        </div>
      </div>
      <div
        ref={headerTitle3}
        className={`product-container-${array[2].className} ${
          isVisible3 ? "animate" : ""
        }`}
      >
        <Image src={array[2].image} alt={array[2].title} placeholder = {"blur"} fill />
        <div className="product-name-container">
          <p>{array[2].title}</p>
        </div>
      </div>
    </>
  );
}

export default Products;
