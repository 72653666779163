import React from "react";
import styles from "./home.module.css";
import { FaAngleDown } from "react-icons/fa6";
import Image from "next/image";
import GoldBlendImage from "../../../public/images/GoldBlend2.png";

export default function Home({ scroll }) {
  return (
    <div
      className={styles.home}
      id="home-section"
      style={{ "--scroll": `${scroll}` }}
    >
      <div className={styles.homehero}>
        <div className={styles.coffee_image_right}>
          <Image src={GoldBlendImage} placeholder = {"blur"} style={{height:'auto'}} alt="" />
        </div>
        <div className={styles.homeheadercontainer}>
          <h1 className={styles.homeheaderdesc}>
            Embark on a Coffee Adventure with
          </h1>
          <h1 className={styles.homeheadertitle}>BeanEmpire</h1>
        </div>
      </div>
      <div
        className={`${styles.circle_arrow} ${scroll > 30 ? styles.hide : ""}`}
      >
        <FaAngleDown className={styles.arrow_down} />
        <FaAngleDown className={styles.arrow_down2} />
      </div>
    </div>
  );
}
