import React from "react";
import styles from "./services.module.css";
import SectionHeader from "../section-header/SectionHeader";
import Accordian from "./components/Accordian";
import { useInView } from "react-intersection-observer";
import placeholderImage from '../../../public/images/placeholderimage.png'
import Image from "next/image";
export default function Services() {
  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });
  const { ref: serviceImage, inView: isimageVisible } = useInView({
    rootMargin: "-100px",
  });
  const { ref: serviceContent, inView: isContentVisible } = useInView({
    rootMargin: "-100px",
    delay: 300,
  });

  return (
    <div className={styles.services} id="services-section">
      <SectionHeader title={"Services"} side={"right"} color={"#E9E1CC"} />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>
          Beyond the Beans: Experience the Full Spectrum of Bean Empire's
          Services
        </h2>
        <h3>
          At Bean Empire, our dedication to coffee extends beyond sourcing the
          finest beans. We offer a comprehensive range of services designed to
          enhance your coffee experience and cater to your unique needs. Explore
          the diverse ways we can enrich your coffee journey:
        </h3>
      </div>
      <div className={styles.servicesHero}>
        <div
          ref={serviceImage}
          className={`${styles.servicesImageContainer} ${
            isimageVisible ? styles.animate : ""
          }`}
        >
          <Image src={placeholderImage} placeholder = {"blur"} alt="plceimage" style={{height:'auto'}} />
        </div>
        <div
          ref={serviceContent}
          className={`${styles.servicesList} ${
            isContentVisible ? styles.animate : ""
          }`}
        >
          <h3>Explore the diverse ways we can enrich your coffee journey:</h3>
          <Accordian />
        </div>
      </div>
    </div>
  );
}
