import React from "react";
import styles from "./certificationsview.module.css";
import { useInView } from "react-intersection-observer";
import Image from "next/image";

export default function PropertiesView({ image, title, desc }) {
  const { ref: headerTitle, inView: isVisible } = useInView({
    rootMargin: "-100px",
  });

  return (
    <div
      ref={headerTitle}
      className={`${styles.certificationsviewContainer} ${
        isVisible ? styles.show : ""
      }`}
    >
      <Image src={image} alt="" height={80} width={80} />
      <p className={styles.certificationsviewHeader}>{title}</p>
      <p className={styles.certificationsviewDesc}>{desc}</p>
      {/* <button>View</button> */}
    </div>
  );
}
