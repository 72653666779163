import React, { useState } from "react";
import styles from "./accordian.module.css";

function Dropdown() {
  const data = [
    {
      title: "Coffee Subscriptions",
      content:
        "Enjoy the convenience of freshly roasted coffee delivered to your doorstep. Customize your subscription to your preferred frequency, roast profile, and grind size.",
    },
    {
      title: "Corporate Gifting",
      content:
        "Show your appreciation with unique and personalized coffee gifts.Our team can help you create customized packages featuring gourmet coffee beans, brewing equipment, and branded merchandise.",
    },
    {
      title: "Coffee Education",
      content:
        "Deepen your coffee knowledge with our engaging workshops and tutorials. Learn about coffee origins, roasting techniques, brewing methods, and more.",
    },
    {
      title: "Coffee Equipment Rentals",
      content:
        "Rent high-quality coffee machines, including Spinel Ciao, Spinel Pinocchio, and Spinel Lolita, with flexible terms and a lifetime warranty. Perfect for startups and established cafes. Contact us to learn more and start serving the perfect cup.",
    },
    {
      title: "Coffee Equipment",
      content:
        "Discover a curated selection of premium coffee equipment, from brewing tools and grinders to storage containers and accesories.",
    },
  ];

  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div className={styles.accordian}>
      {data.map((item, i) => (
        <>
          <div className={styles.item}>
            <div className={styles.title} onClick={() => toggle(i)}>
              <p>{item.title}</p>
              <span
                className={selected === i ? styles.rotateUp : styles.rotateDown}
              >
                +
              </span>
            </div>
            <div
              className={
                selected === i
                  ? `${styles.content} ${styles.show}`
                  : styles.content
              }
            >
              <p>{item.content}</p>
            </div>
          </div>
          <div className={styles.accordianDivider}></div>
        </>
      ))}
    </div>
  );
}

export default Dropdown;
