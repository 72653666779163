import React, { useState } from "react";
import SectionHeader from "../section-header/SectionHeader";
import Slider from "react-slick";
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io";
import { useInView } from "react-intersection-observer";
import { useRouter } from "next/navigation";
import Image from "next/image";
import cremaImage from "../../../public/images/crema.png";
import aromaImage from "../../../public/images/aroma.png";
import goldImage from "../../../public/images/gold.png";

// styles are included in globalstyles page

const images = [
  {
    key: 0,
    image: cremaImage,
    title: "Crema",
    desc: "Crema is our tribute to the art of coffee blending. A harmonious mix of Arabica and Robusta beans creates a smooth, creamy texture that's rare and sought-after. This blend is a testament to our commitment to exceptional coffee experiences.",
  },
  {
    key: 1,
    image: goldImage,
    title: "Gold blend",
    desc: "Experience the pinnacle of coffee craftsmanship with our Gold Blend. Sourced from the finest 100% Arabica beans, this medium roast is crafted to awaken your senses. Perfectly balanced, each cup offers a symphony of sweet and delicate aromas, transporting you to coffee heaven.",
  },
  {
    key: 2,
    image: aromaImage,
    title: "Aroma",
    desc: "Indulge in the robust intensity of Aroma, a coffee blend that promises a rich and enveloping experience. Each bean is selected for its unique contribution to a grand tapestry of flavors, creating a coffee that's as rich in taste as it is in aroma.",
  },
  {
    key: 3,
    image: cremaImage,
    title: "Crema",
    desc: "Crema is our tribute to the art of coffee blending. A harmonious mix of Arabica and Robusta beans creates a smooth, creamy texture that's rare and sought-after. This blend is a testament to our commitment to exceptional coffee experiences.",
  },
  {
    key: 4,
    image: goldImage,
    title: "Gold blend",
    desc: "Experience the pinnacle of coffee craftsmanship with our Gold Blend. Sourced from the finest 100% Arabica beans, this medium roast is crafted to awaken your senses. Perfectly balanced, each cup offers a symphony of sweet and delicate aromas, transporting you to coffee heaven.",
  },
  {
    key: 5,
    image: aromaImage,
    title: "Aroma",
    desc: "Indulge in the robust intensity of Aroma, a coffee blend that promises a rich and enveloping experience. Each bean is selected for its unique contribution to a grand tapestry of flavors, creating a coffee that's as rich in taste as it is in aroma.",
  },
];

export default function Productlist() {
  const router = useRouter();

  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <IoIosArrowDropright />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <IoIosArrowDropleft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    autoplay: true,
    speed: 600,
    autoplaySpeed: 2500,
    infinite: true,
    dots: true,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div
      className="productlist"
      id="product-section"
      style={{ position: "relative" }}
    >
      <SectionHeader title={"Product List"} side={"left"} color={"#E9E1CC"} />
      <div
        ref={headerTitle}
        className={`header-title-container ${isHeaderderVisible ? "show" : ""}`}
      >
        <h2>Explore Our Exquisite Collection of Specialty Coffee Beans</h2>
        <h3>
          At Bean Empire, we take pride in offering the finest selection of
          specialty coffee beans from around the world. Our beans are sourced
          from renowned coffee-growing regions such as Colombia, Brazil,
          Argentina, Peru, and Ethiopia. Each bean tells a unique story,
          boasting an intricate tapestry of flavors and aromas waiting to be
          discovered.
        </h3>
      </div>
      <div className="slide-container">
        <Slider {...settings}>
          {images.map((item, key) => (
            <div
              key={key}
              className={key === imageIndex ? "activeSlide" : "slide"}
            >
              <Image src={item.image} placeholder={"blur"} alt={item.title} />
              <div className="product-description">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "0%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <button style={{ border: "none" }} onClick={() => router.push("/shop")}>
          Shop Now
        </button>
      </div>
    </div>
  );
}
