import React from "react";
import styles from "./subscriptions.module.css";
import SectionHeader from "../section-header/SectionHeader";
import SubscriptionView from "./components/SubscriptionView";
import { useInView } from "react-intersection-observer";
import subscriptionImage from '../../../public/images/mokup.png'
import mockup2Image from '../../../public/images/mokup2.png'
import mockup3Image from '../../../public/images/mokup3.png'
export default function Subscriptions() {
  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });

  const description1 = [
    "Monthly delivery of a curated selection of coffee, ideal for those new to the world of coffee.",
    "Includes a variety of mild and approachable blends.",
    "Brewing guide with tips for beginners.",
    "Sample packs of different roasts to explore tastes.",
    "Access to online coffee workshops and tutorials.",
  ];
  const description2 = [
    "Bi-weekly delivery of premium, exotic, and single-origin coffees.",
    "Detailed tasting notes and origin stories for each selection.",
    "Includes limited edition and specialty roasts occasionally.",
    "Discounts on Bean Empire merchandise and accessories.",
    "Exclusive access to members-only virtual coffee tasting events.",
  ];
  const description3 = [
    "Regular supply of high-quality, bulk coffee beans tailored for café use.",
    "Option to customize blends and roasts as per café preferences.",
    "Training materials and support for staff on brewing techniques.",
    "Priority customer service and dedicated account management.",
    "Marketing materials and co-branding opportunities for enhanced café visibility.",
  ];

  return (
    <div className={styles.subscriptions} id="subscription-section">
      <div className={styles.separator}></div>
      <SectionHeader title={"Subscriptions"} side={"right"} color={"#21201E"} />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>
          Savor Perfection & Brew Up Premium Moments with Our Coffee
          Subscription!
        </h2>
        <h3>
          Embark on a coffee lover's journey with our exclusive subscription!
          Indulge in the rich aroma of freshly roasted beans, early access to
          new blends, and members-only discounts. Elevate your coffee ritual and
          experience the true joy of a premium brew. Subscribe now and let every
          cup be a celebration of flavor and refinement. Your perfect coffee
          moment awaits!
        </h3>
      </div>
      <div className={styles.subscriptionContainer}>
        <SubscriptionView
          name={"Bean Beginnings"}
          props={description1}
          image={subscriptionImage}
          side={"left"}
          price={"$10"}
        />
        <SubscriptionView
          name={"Aroma Aficionado"}
          props={description2}
          image={mockup2Image}
          side={"middle"}
          price={"$25"}
        />
        <SubscriptionView
          name={"Café Cultivator"}
          props={description3}
          image={mockup3Image}
          side={"right"}
          isEnterprice={true}
          price={"Let's Talk"}
        />
      </div>
    </div>
  );
}
