import React from "react";
import styles from "./sectionheader.module.css";
import { useInView } from "react-intersection-observer";

export default function HeaderRight({ title, side, color }) {
  const { ref: secHead, inView: isSecHeaderderVisible } = useInView({
    rootMargin: "-25px",
  });

  return (
    <div
      ref={secHead}
      className={`${isSecHeaderderVisible ? styles.show : ""} ${
        side === "left"
          ? styles.sectionheaderContainerLeft
          : styles.sectionheaderContainerRight
      }`}
    >
      <div
        className={
          side === "left" ? styles.leftRectangle : styles.rightRectangle
        }
      ></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <p className={styles.sectionheadertitle} style={{ color: color }}>
        {title}
      </p>
    </div>
  );
}
