import Image from "next/image";
import React from "react";
import { useInView } from "react-intersection-observer";

function SubscriptionView({ side, image, name, props, price, isEnterprice }) {
  const { ref: subsView, inView: isVisible } = useInView({
    rootMargin: "-150px",
  });
  return (
    <div
      ref={subsView}
      className={`subsview-container-${side} ${isVisible ? "animate" : ""}`}
    >
      <div className="image-container">
        <Image src={image} alt="" placeholder = {"blur"} />
      </div>
      <div className="gradient">
        <p>{name}</p>
      </div>
      <div className="desc-container">
        {isEnterprice ? (
          <div className="price-container">
            <p className="price">Let's Talk</p>
          </div>
        ) : (
          <div className="price-container">
            <p className="price">{price}</p>
            <p className="month">/Month</p>
          </div>
        )}

        <div className="subscription-desc">
          {props?.map((item, idx) => {
            return (
              <ul key={idx}>
                <li>
                  <p>{item}</p>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionView;
