import React, { useState } from "react";
import styles from "./accordianFaq.module.css";
import { useInView } from "react-intersection-observer";

const AccordianFaq = ({ data1 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px",
  });

  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div ref={ref} className={styles.accordian}>
      {data1.map((item, i) => (
        <div
          key={i}
          className={`${styles.item} ${inView ? styles.fadeIn : ""}`}
        >
          <div className={styles.title} onClick={() => toggle(i)}>
            <p>{item.title}</p>
            <span
              className={selected === i ? styles.rotateUp : styles.rotateDown}
            >
              +
            </span>
          </div>
          <div
            className={
              selected === i
                ? `${styles.content} ${styles.show}`
                : styles.content
            }
          >
            <p>{item.content}</p>
          </div>
          <div className={styles.accordianDivider}></div>
        </div>
      ))}
    </div>
  );
};

export default AccordianFaq;
