import React from "react";
import styles from "./coffeeequipment.module.css";
import SectionHeader from "../section-header/SectionHeader";
import Products from "./components/Products";
import { useInView } from "react-intersection-observer";
import nespressoPodImage from '../../../public/images/NespressoPod.jpg'
import commercialEspressoImage from '../../../public/images/CommercialEspresso.jpg'
import groundCoffeeImage from '../../../public/images/GroundCoffee.jpg'
import accessoriesImage from '../../../public/images/CoffeeAccessories.jpg'
import grinderImage from '../../../public/images/CoffeeGrinders.jpg'
import houseHoldImage from '../../../public/images/houseHold.jpg'

export default function CoffeeEquipment() {
  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });

  const products = [
    [
      {
        image: nespressoPodImage,
        title: "Nespresso Pod Coffee Machines",
        className: "col1",
      },
      {
        image: commercialEspressoImage,
        title: "Commercial Espresso Machines",
        className: "col2",
      },
      {
        image: grinderImage,
        title: "Coffee Grinders",
        className: "col3",
      },
    ],
    [
      {
        image: groundCoffeeImage,
        title: "Ground Coffee",
        className: "col1",
      },
      {
        image: accessoriesImage,
        title: "Coffee Accessories",
        className: "col2",
      },
      {
        image: houseHoldImage,
        title: "Household Coffee Accesories",
        className: "col3",
      },
    ],
  ];

  return (
    <div className={styles.coffeeEquipment} id="coffee-equipment-section">
      <SectionHeader
        title={"Coffee Equipment"}
        side={"left"}
        color={"#21201E"}
      />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>
          Brew & Sip in Style, Unveiling Our Coffee Essentials Collection!
        </h2>
        <h3>
          Elevate your coffee experience with our curated collection. From sleek
          machines to artisanal cups, find the perfect essentials for a stylish
          brew. Explore quality at delightful prices – choose excellence in
          every sip!
        </h3>
      </div>
      <div className={styles.coffeeEquipmentHero}>
        {products.map((item, idx) => (
          <Products key={idx} array={item} />
        ))}
      </div>
    </div>
  );
}
