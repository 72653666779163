"use client";

import NavbarMain from "../components/navbar-main/NavbarMain";
import TopSection from "../components/top-section/TopSection";
import Productlist from "../components/product-list/Productlist";
import Subscriptions from "../components/subscriptions/Subscriptions";
import CoffeeEquipment from "../components/coffee-equipment/CoffeeEquipment";
import Certifications from "../components/certifications/Certifications";
import Services from "../components/services/Services";
import Contactus from "../components/contactus/Contactus";
import { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import Faqs from "../components/faqs/Faqs";

export default function Home() {
  const [scroll, setScroll] = useState(0);

  const calculateScrollPercentage = () => {
    const htmlElement = document.documentElement;
    const displayHeight = Math.max(htmlElement.clientHeight, 800);
    const percentScreenHeightScrolled =
      (htmlElement.scrollTop / displayHeight) * 100;
    return Math.min(percentScreenHeightScrolled, 200);
  };

  const animateScroll = (targetScroll, duration) => {
    const startTime = performance.now();

    const scrollStep = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

      const newScroll =
        scroll + (targetScroll - scroll) * easeInOutQuad(progress);
      window.scrollTo(0, newScroll);

      if (elapsedTime < duration) {
        window.requestAnimationFrame(scrollStep);
      }
    };

    window.requestAnimationFrame(scrollStep);
    document.body.style.overflowY = "auto"; // Re-enable scrolling
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    document.body.style.overflowY = "hidden"; // Disable scrolling

    const delay = 1000;

    setTimeout(() => {
      const htmlElement = document.documentElement;
      const targetScroll = htmlElement.clientHeight;
      const duration = 2500;
      animateScroll(targetScroll, duration);
    }, delay);

    window.addEventListener("scroll", () =>
      setScroll(calculateScrollPercentage)
    );
    window.addEventListener("resize", () =>
      setScroll(calculateScrollPercentage)
    );

    return () => {
      window.removeEventListener("scroll", () =>
        setScroll(calculateScrollPercentage)
      );
      window.removeEventListener("resize", () =>
        setScroll(calculateScrollPercentage)
      );
    };
  }, []);

  return (
    <>
      <main>
        <NavbarMain scroll={scroll} />
        <TopSection scroll={scroll} />
        <Productlist />
        <Subscriptions />
        <CoffeeEquipment />
        <Certifications />
        <Services />
        <Faqs />
        <Contactus />
        <Footer />
      </main>
    </>
  );
}
