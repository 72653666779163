import React from "react";
import styles from "./certifications.module.css";
import SectionHeader from "../section-header/SectionHeader";
import CertificationsView from "./components/CertificationsView";
import { useInView } from "react-intersection-observer";

export default function Certifications() {
  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });

  const data = [
    {
      image: "/images/quality.png",
      title: "QUALITY",
      desc: "This certification indicates a commitment to high-quality coffee production, ensuring exceptional taste and aroma.",
    },
    {
      image: "/images/tree.png",
      title: "ENVIRONMENT",
      desc: "This highlights their dedication to environmentally friendly practices, focusing on sustainability in coffee production.",
    },
    {
      image: "/images/organic.png",
      title: "ORGANIC",
      desc: "This certification signifies that the coffee is produced following organic farming practices, without the use of synthetic pesticides and fertilizers.",
    },
    {
      image: "/images/kosher.png",
      title: "KOSHER",
      desc: "Indicates compliance with the strict dietary standards of Jewish law, ensuring the coffee meets Kosher requirements.",
    },
    {
      image: "/images/halal.png",
      title: "HALAL",
      desc: "Signifies that the coffee is prepared according to Islamic law, making it permissible for consumption by those following Halal dietary guidelines.",
    },
    {
      image: "/images/star.png",
      title: "FSSC 22000",
      desc: "A food safety certification that ensures the company adheres to the highest standards of quality and safety in their production processes.",
    },
  ];

  return (
    <div className={styles.certifications} id="certifications-section">
      <div className={styles.separator}></div>
      <SectionHeader title={"Certifications"} side={"left"} color={"#E9E1CC"} />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>Sip with Confidence: Our Coffee, Certified for Excellence!</h2>
        <h3>
          Indulge in a cup of assurance with our certified quality coffee. Each
          blend carries the mark of excellence, backed by stringent quality
          certificates. From cultivation to cup, our commitment to quality
          shines through. Explore our certified coffees and sip with confidence,
          knowing you're experiencing the pinnacle of coffee perfection.
        </h3>
      </div>
      <div className={styles.certificationsContainer}>
        {data.map((item, idx) => (
          <CertificationsView
            key={idx}
            image={item.image}
            title={item.title}
            desc={item.desc}
          />
        ))}
      </div>
    </div>
  );
}
