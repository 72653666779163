import React from "react";
import styles from "./footer.module.css";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import Image from "next/image";
import logo from '../../../public/bean_empire.png'

function Footer() {
  return (
    <div id="footer-section" className={styles.footerContainer}>
      <div className={styles.footerHero}>
        <div className={styles.logoDiv}>
          <Image
            src={logo}
            alt="alt"
            placeholder = {"blur"}
            width={150}
            height={150}
          />
        </div>
        <div className={styles.companyDiv}>
          <div className={styles.menulistView}>
            <h4>Company</h4>
            <ul>
              <li>
                <a href="./">Introduction</a>
              </li>
              <li>
                <a href="./">Properties</a>
              </li>
              <li>
                <a href="./">Product List</a>
              </li>
              <li>
                <a href="./">Services</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.supportDiv}>
          <div className={styles.menulistView}>
            <h4>Support</h4>
            <ul>
              <li>
                <a href="https://bean-empire-attachments.s3.ap-southeast-1.amazonaws.com/docs/Terms+and+Conditions.pdf">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="https://bean-empire-attachments.s3.ap-southeast-1.amazonaws.com/docs/Privacy+Policy.pdf">
                  Privacy Statement
                </a>
              </li>
              <li>
                <a href="https://bean-empire-attachments.s3.ap-southeast-1.amazonaws.com/docs/Refund+Policy.pdf">
                  Refund Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.aboutDiv}>
          <div className={styles.menulistView}>
            <h4>About</h4>
            <ul>
              <li>
                <a href="">Careers</a>
              </li>
              <li>
                <a href="">About Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.socialDiv}>
          <h4>Follow us on</h4>
          <div className={styles.socialmediaIcons}>
            <a
              href="https://www.facebook.com/profile.php?id=61555470165570"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebookSquare fontSize="20px" />
            </a>
            <a
              href="https://www.instagram.com/beanempirecoffee?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaInstagram fontSize="20px" />
            </a>
            <a
              href="https://www.beanempirecoffee.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaLinkedin fontSize="20px" />
            </a>
            <a
              href="https://www.beanempirecoffee.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaSquareXTwitter fontSize="20px" />
            </a>
          </div>
        </div>
        <div className={styles.accessibility}></div>
      </div>
      <div className={styles.footerDivider}></div>
      <div className={styles.footerRights}>
        <p>Bean Empire &copy; 2023 All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Footer;
