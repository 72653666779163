import React, { useState } from "react";
import styles from "./inputform.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { constants } from "../../../constants";
import emailjs from "@emailjs/browser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  message: yup.string(),
});

const InputForm = () => {
  const [canClick, setCanClick] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!canClick) return;
      setCanClick(false);

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(constants.GOOGLE_RECAPTCHA || "", {
            action: "submit",
            size: "invisible",
          })
          .then(async (token) => {
            try {
              await emailjs.sendForm(
                constants.EMAIL_SERVICE.SERVICE_ID,
                constants.EMAIL_SERVICE.TEMPLATE_ID,
                "#contact-form",
                constants.EMAIL_SERVICE.PUBLIC_KEY
              );
            } catch (error) {
              console.log(error);
            }
          });
      });

      // Reset the state after some condition or time

      setTimeout(() => setCanClick(true), 5000);
    },
  });
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={constants.GOOGLE_RECAPTCHA || ""}>
        <form
          id="contact-form"
          onSubmit={formik.handleSubmit}
          className={styles.form}
        >
          <div className={styles.formcontdiv}>
            <label htmlFor="name" className={styles.formLabel}></label>
            <input
              placeholder="Name"
              type="text"
              id="name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              className={styles.formInput}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={styles.formError}>{formik.errors.name}</div>
            ) : null}
            <label htmlFor="email" className={styles.formLabel}></label>
            <input
              placeholder="Email Address"
              type="text"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className={styles.formInput}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className={styles.formError}>{formik.errors.email}</div>
            ) : null}
            <label htmlFor="phone" className={styles.formLabel}></label>
            <input
              placeholder="Phone Number"
              type="text"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={styles.formInput}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className={styles.formError}>{formik.errors.phone}</div>
            ) : null}
            <label htmlFor="message" className={styles.formLabel}></label>
            <textarea
              placeholder="Your Message"
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              className={styles.formTextarea}
              rows="4"
            />
            {formik.touched.message && formik.errors.message ? (
              <div className={styles.formError}>{formik.errors.message}</div>
            ) : null}

            <button
              type="submit"
              className={`${styles.formButton} ${
                canClick ? "" : styles.disable
              }`}
              disabled={!canClick}
            >
              {!canClick ? "Sent" : "Submit"}
            </button>
          </div>
        </form>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default InputForm;
