import React from "react";
import styles from "./contactus.module.css";
import SectionHeader from "../section-header/SectionHeader";
import InputForm from "./components/InputForm";

import { useInView } from "react-intersection-observer";
import Image from "next/image";
import emailImage from '../../../public/images/email.png'
import locationImage from '../../../public/images/location.png'
import phoneImage from '../../../public/images/phone.png'
export default function Contactus() {
  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });
  return (
    <div className={styles.contactus} id="contactus-section">
      <div className={styles.separator}></div>
      <SectionHeader title={"Contact Us"} side={"right"} color={"#21201E"} />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>Let's connect and explore the world of coffee together!</h2>
        <h3>
          At Bean Empire, we believe in building connections and fostering a
          community of coffee enthusiasts. We are always happy to hear from you,
          answer your questions, and assist you with any inquiries you may have.
        </h3>
      </div>
      <div className={styles.contactusContainer}>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <InputForm />
          </div>
          <div className={styles.contactDetails}>
            <div className={styles.detailContainer}>
              <div className={styles.detailContainerImage}>
                <Image src={emailImage} alt="" width={25} height={20} />
              </div>
              <div className={styles.detailContainerDetails}>
                <h4>Email</h4>
                <p>info@beanempirecoffee.com</p>
              </div>
            </div>
            <div className={styles.detailContainer}>
              <Image
                style={{ width: "30px", height: "30px" }}
                src={locationImage}
                alt=""
                width={25}
                height={30}
              />
              <div className={styles.detailContainerDetails}>
                <h4>Address</h4>
                <p>
                  Bean Empire Coffee (Pvt) Ltd, South Koswatta, Koswatta, Sri
                  Lanka
                </p>
              </div>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.detailContainerImage}>
                <Image
                  style={{ width: "25px", height: "35px" }}
                  src={phoneImage}
                  alt=""
                  width={25}
                  height={35}
                />
              </div>
              <div className={styles.detailContainerDetails}>
                <h4>Call Us</h4>
                <p>+94 (76) 3272615</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
