import React from "react";
import styles from "./propertiesview.module.css";
import Image from "next/image";

export default function PropertiesView({
  image,
  side,
  title,
  desc,
  isPropsVisible,
}) {
  return (
    <div
      className={`${
        side === "left"
          ? `${styles.propertiesviewContainerLeft} ${
              isPropsVisible ? styles.show : ""
            }`
          : `${styles.propertiesviewContainerRight} ${
              isPropsVisible ? styles.show : ""
            }`
      }`}
    >
      <Image src={image} alt="" height={50} placeholder={"blur"} />
      <p className={`${styles.propertiesviewHeader}`}>{title}</p>
      <p className={`${styles.propertiesviewDesc}`}>{desc}</p>
    </div>
  );
}
