const constants = {
  GOOGLE_RECAPTCHA: "6LeYJ8ApAAAAAFyey0YxBKX_hgMYAmkhXAbqqjag",
  EMAIL_SERVICE: {
    SERVICE_ID: "service_cbtd3t1",
    TEMPLATE_ID: "template_x1k5dds",
    PUBLIC_KEY: "2S3x6XBl4-heBVLkO",
  },
};

const paymentStatusType = {
  SUCCESS: "2",
  PENDING: "0",
  CANCELED: "-1",
  FAILED: "-2",
  CHARGEDBACK: "-3",
};

export { constants, paymentStatusType };
