import React from "react";
import Home from "../home/Home";
import Properties from "../properties/Properties";
import styles from "./topsection.module.css";
import { useRouter } from "next/navigation";
import topSectionImage from '../../../public/images/image.png'
import Image from "next/image";
function TopSection({ scroll }) {
  const router = useRouter();

  const scrollToSection = (section) => {
    const homeSection = document.getElementById(`${section}`);
    if (homeSection) {
      homeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="top-section" className={styles.topsection}>
      <div
        className={
          scroll < 100
            ? styles.coffee_image_left
            : `${styles.coffee_image_left_scolled} ${
                scroll > 199 ? styles.hide : ""
              }`
        }
        style={{ "--scroll": `${scroll}` }}
      >
        <Image src={topSectionImage} style={{height:'auto'}} alt="image" placeholder = {"blur"} />
      </div>
      <div
        className={`${styles.headerbuttoncontainer} ${
          80 <= scroll && scroll < 110 ? styles.show : ""
        }`}
      >
        <button
          className={styles.button_subcription}
          onClick={() => {
            router.push("/sample-request");
          }}
        >
          Request a Sample
        </button>
        <button
          className={styles.button_contactus}
          onClick={() => {
            router.push("/shop");
          }}
        >
          Shop Now
        </button>
      </div>
      <Home scroll={scroll} />
      <Properties scroll={scroll} />
    </div>
  );
}

export default TopSection;
