import React from "react";
import styles from "./properties.module.css";
import SectionHeader from "../section-header/SectionHeader";
import PropertiesView from "./components/PropertiesView";
import beanImage from "../../../public/images/bean.png";
import lightningImage from "../../../public/images/lightning.png";
import handShakeImage from "../../../public/images/handshake.png";
import coffeeImage from "../../../public/images/coffee.png";
import aromaMainBackImage from "../../../public/images/AromaMainBack.png";
import cremaMainImage from "../../../public/images/CremaMain.png";
import goldBlendImage from "../../../public/images/GoldBlend.png";
import Image from "next/image";
export default function Properties({ scroll }) {
  const propertiesArray = [
    {
      side: "left",
      title: "Premium Quality Beans",
      desc: "Sourced from top plantations in Asia, South America, and Central America, Bean Empire offers only the finest Arabica and Robusta beans, ensuring a rich, flavorful coffee experience.",
      image: beanImage,
    },
    {
      side: "right",
      title: "Artisan Roasting",
      desc: "Combining traditional methods with modern techniques, Bean Empire's artisan roasting process highlights the unique flavors and aromas of each bean, resulting in perfectly balanced coffee.",
      image: lightningImage,
    },
    {
      side: "left",
      title: "Sustainable and Ethical Sourcing",
      desc: "Bean Empire is dedicated to ethical sourcing, partnering with farmers who practice sustainable agriculture and support fair labor conditions, ensuring the well-being of coffee-growing communities.",
      image: handShakeImage,
    },
    {
      side: "right",
      title: "Diverse Flavor Profiles",
      desc: "Offering a wide range of blends and single-origin coffees, Bean Empire caters to all palates, from smooth, creamy notes to rich, aromatic complexities, delivering an exceptional coffee experience.",
      image: coffeeImage,
    },
  ];

  return (
    <div
      className={`${styles.properties}`}
      id="properties-section"
      style={{ "--scroll": scroll }}
    >
      <div className={`${styles.sectionheaderContainer}`}>
        <SectionHeader title={"Properties"} side={"right"} color={"#E9E1CC"} />
      </div>
      <h2>Unveiling the Essence of Coffee</h2>
      <h3>A Journey Through Origins, Roasts, and Brewing Techniques</h3>
      <div
        className={`${styles.propImageMiddle}`}
        style={{ "--scroll": scroll }}
      >
        <Image
          className={`${styles.imgMiddle} ${
            scroll > 170 ? styles.animate1 : ""
          } ${scroll > 199 ? styles.animate2 : ""}`}
          src={aromaMainBackImage}
          placeholder={"blur"}
          height={400}
          alt=""
        />
      </div>
      <div className={`${styles.propImageLeft}`} style={{ "--scroll": scroll }}>
        <Image
          className={`${styles.imgLeft} ${
            scroll > 170 ? styles.animate1 : ""
          } ${scroll > 199 ? styles.animate2 : ""}`}
          src={cremaMainImage}
          placeholder={"blur"}
          height={450}
          alt=""
        />
      </div>
      <div
        className={`${styles.propImageRight} ${
          scroll > 199 ? styles.show : ""
        }`}
        style={{ "--scroll": scroll }}
      >
        <Image
          height={550}
          className={`${styles.imgRight} ${
            scroll > 170 ? styles.animate1 : ""
          }`}
          src={goldBlendImage}
          placeholder={"blur"}
          alt=""
        />
      </div>
      <div className={`${styles.propertiesHero}`}>
        {propertiesArray.map((property, index) => (
          <PropertiesView
            key={index}
            {...property}
            isPropsVisible={scroll > 199}
          />
        ))}
      </div>
    </div>
  );
}
