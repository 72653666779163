import React from "react";
import styles from "./faq.module.css";
import SectionHeader from "../section-header/SectionHeader";
import { useInView } from "react-intersection-observer";
import AccordianFaq from "./components/AccordianFaq";

export default function Faq() {
  const data = [
    {
      title:
        "Q1 : What types of coffee beans do you offer for cafes in Sri Lanka?",
      content:
        "We offer three premium coffee bean blends perfect for cafes: Gold blend (100% Arabica), Aroma Blend (80% Arabica, 20% Robusta), and Crema blend (70% Arabica, 30% Robusta). Our Gold blend is our most premium product, popular in the USA and recipient of a special award.",
    },
    {
      title: "Q2 : Why are your coffee beans ideal for cafes in Sri Lanka?",
      content:
        "Our coffee beans are directly imported from a top-quality Italian roastery, using artisan roasting techniques. We maintain high standards in roasting, storing, and sourcing, ensuring unique taste profiles for each blend. Unlike the commodity coffee commonly available in Sri Lanka, our specialty coffee is sourced from renowned origins like Colombia, Brazil, Argentina, Peru, and Ethiopia.",
    },
    {
      title:
        "Q3 : Can your coffee help a startup cafe without a barista in Sri Lanka?",
      content:
        "Yes, our ESE pods (Classico and Gold) and ESE pod machines (Spinel Ciao, Spinel Pinocchio, and Spinel Lolita) are perfect for startup cafes in Sri Lanka. These machines are more affordable than traditional espresso machines and come with a lifetime warranty. They are user-friendly, meaning a barista is not required to serve high-quality coffee.",
    },
    {
      title: "Q4 : Do you provide coffee subscriptions for cafes in Sri Lanka?",
      content:
        "Yes, we offer coffee subscriptions for both Pods and Beans. Our subscription packages will be released soon, providing a convenient and consistent supply of premium coffee for your cafe.",
    },
    {
      title: "Q5 : What certifications do your coffee products have?",
      content:
        "Our manufacturer holds several prestigious certifications, including ISO 9001:2015, ISO 14001:2015, BIOAGRICERT Certification, Kosher certification, Halal, and FSSC 2200, ensuring the highest quality standards.",
    },
    {
      title: "Q6 : How do you source your coffee beans?",
      content:
        "We use ethical sourcing practices, and our manufacturer is certified for this. Our beans come from renowned coffee-growing regions such as Colombia, Brazil, Argentina, Peru, and Ethiopia.",
    },
    {
      title:
        "Q7 : How can cafes in Sri Lanka contact you for support or inquiries?",
      content:
        "Cafes can reach us through email, WhatsApp, or mobile number for any support or inquiries. We are always here to assist our customers.",
    },
    {
      title: "Q8 : What is your return or refund policy for cafes?",
      content:
        "Our return and refund policy is detailed on our website. We ensure a transparent and hassle-free process for all our customers.",
    },
    {
      title:
        "Q9 : What are your shipping policies and delivery times for cafes in Sri Lanka?",
      content:
        "We offer local deliveries within Sri Lanka. Bulk deliveries are handled by our delivery service, while retail deliveries are managed by third-party delivery services. We ensure timely and reliable delivery for all orders.",
    },
    {
      title: "Q10 : Do you provide any brewing tips for cafes in Sri Lanka?",
      content:
        "Absolutely! To achieve the best espresso, we recommend using good quality water, distributing it correctly to prevent channeling through the puck, preheating the brew head, and experimenting with different grind settings.",
    },
    {
      title: "Q11 : Can cafes in Sri Lanka purchase coffee beans in bulk?",
      content:
        "Yes, we offer bulk purchase options for cafes. We recommend contacting us to arrange a demo before making a bulk purchase to ensure you are fully satisfied with our products.",
    },
    {
      title:
        "Q12 : How can cafes ensure a continuous supply of your coffee in Sri Lanka?",
      content:
        "We understand the importance of a reliable supply for cafes. We are open to making agreements to ensure a continuous and consistent supply of our coffee products.",
    },
  ];
  const data1 = [
    {
      title:
        "Q1 : What types of coffee beans do you offer for cafes in Sri Lanka?",
      content:
        "We offer three premium coffee bean blends perfect for cafes: Gold blend (100% Arabica), Aroma Blend (80% Arabica, 20% Robusta), and Crema blend (70% Arabica, 30% Robusta). Our Gold blend is our most premium product, popular in the USA and recipient of a special award.",
    },
    {
      title: "Q2 : Why are your coffee beans ideal for cafes in Sri Lanka?",
      content:
        "Our coffee beans are directly imported from a top-quality Italian roastery, using artisan roasting techniques. We maintain high standards in roasting, storing, and sourcing, ensuring unique taste profiles for each blend. Unlike the commodity coffee commonly available in Sri Lanka, our specialty coffee is sourced from renowned origins like Colombia, Brazil, Argentina, Peru, and Ethiopia.",
    },
    {
      title:
        "Q3 : Can your coffee help a startup cafe without a barista in Sri Lanka?",
      content:
        "Yes, our ESE pods (Classico and Gold) and ESE pod machines (Spinel Ciao, Spinel Pinocchio, and Spinel Lolita) are perfect for startup cafes in Sri Lanka. These machines are more affordable than traditional espresso machines and come with a lifetime warranty. They are user-friendly, meaning a barista is not required to serve high-quality coffee.",
    },
  ];
  const data2 = [
    {
      title: "Q4 : Do you provide coffee subscriptions for cafes in Sri Lanka?",
      content:
        "Yes, we offer coffee subscriptions for both Pods and Beans. Our subscription packages will be released soon, providing a convenient and consistent supply of premium coffee for your cafe.",
    },
  ];
  const data3 = [
    {
      title: "Q5 : What certifications do your coffee products have?",
      content:
        "Our manufacturer holds several prestigious certifications, including ISO 9001:2015, ISO 14001:2015, BIOAGRICERT Certification, Kosher certification, Halal, and FSSC 2200, ensuring the highest quality standards.",
    },
    {
      title: "Q6 : How do you source your coffee beans?",
      content:
        "We use ethical sourcing practices, and our manufacturer is certified for this. Our beans come from renowned coffee-growing regions such as Colombia, Brazil, Argentina, Peru, and Ethiopia.",
    },
  ];
  const data4 = [
    {
      title:
        "Q7 : How can cafes in Sri Lanka contact you for support or inquiries?",
      content:
        "Cafes can reach us through email, WhatsApp, or mobile number for any support or inquiries. We are always here to assist our customers.",
    },
    {
      title: "Q8 : What is your return or refund policy for cafes?",
      content:
        "Our return and refund policy is detailed on our website. We ensure a transparent and hassle-free process for all our customers.",
    },
  ];

  const data5 = [
    {
      title:
        "Q9 : What are your shipping policies and delivery times for cafes in Sri Lanka?",
      content:
        "We offer local deliveries within Sri Lanka. Bulk deliveries are handled by our delivery service, while retail deliveries are managed by third-party delivery services. We ensure timely and reliable delivery for all orders.",
    },
  ];
  const data6 = [
    {
      title: "Q10 : Do you provide any brewing tips for cafes in Sri Lanka?",
      content:
        "Absolutely! To achieve the best espresso, we recommend using good quality water, distributing it correctly to prevent channeling through the puck, preheating the brew head, and experimenting with different grind settings.",
    },
  ];
  const data7 = [
    {
      title: "Q11 : Can cafes in Sri Lanka purchase coffee beans in bulk?",
      content:
        "Yes, we offer bulk purchase options for cafes. We recommend contacting us to arrange a demo before making a bulk purchase to ensure you are fully satisfied with our products.",
    },
  ];
  const data8 = [
    {
      title:
        "Q12 : How can cafes ensure a continuous supply of your coffee in Sri Lanka?",
      content:
        "We understand the importance of a reliable supply for cafes. We are open to making agreements to ensure a continuous and consistent supply of our coffee products.",
    },
  ];

  const { ref: headerTitle, inView: isHeaderderVisible } = useInView({
    rootMargin: "-150px",
    triggerOnce: true,
  });

  const { ref: faqContent, inView: isContentVisible } = useInView({
    rootMargin: "-100px",
    delay: 0,
  });

  return (
    <div className={styles.faqs} id="services-section">
      <SectionHeader title={"FAQs"} side={"left"} color={"#E9E1CC"} />
      <div
        ref={headerTitle}
        className={`${styles.headerTitleContainer} ${
          isHeaderderVisible ? styles.show : ""
        }`}
      >
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className={styles.faqsHero}>
        <h3>About Our Coffee Products</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data1} />
        </div>
        <h3>Subscriptions and Special Offers</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data2} />
        </div>
        <h3>Quality and Certifications</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data3} />
        </div>
        <h3>Customer Support and Policies</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data4} />
        </div>
        <h3>Shipping and Delivery</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data5} />
        </div>
        <h3>Brewing Tips for Cafes</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data6} />
        </div>
        <h3>Bulk and Wholesale Options</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data7} />
        </div>
        <h3>Reliability and Supply Agreements</h3>
        <div ref={faqContent} className={styles.faqsList}>
          <AccordianFaq data1={data8} />
        </div>
      </div>
    </div>
  );
}
