import React, { useState } from "react";
import styles from "./navbarMain.module.css";
import { PiListBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { useRouter } from "next/navigation";
import logo from "../../../public/images/bean_empire.png"
import Image from "next/image";
export default function NavbarMain({ scroll }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const router = useRouter();

  const scrollToSection = (section) => {
    const homeSection = document.getElementById(`${section}`);
    if (homeSection) {
      homeSection.scrollIntoView({ behavior: "smooth" });
      setShowDropdown(false);
    }
  };

  return (
    <div
      className={`${styles.navbar} ${scroll > 110 ? styles.scrolled : ""}`}
      style={{ "--scroll": scroll }}
    >
      <div className={styles.navbarLeft}>
        <ul className={styles.navbarList}>
          <li onClick={() => scrollToSection("home-section")}>Home</li>
          <li onClick={() => scrollToSection("properties-section")}>
            Properties
          </li>
          <li onClick={() => scrollToSection("product-section")}>
            Product List
          </li>
          <li></li>
        </ul>
      </div>
      <div
        className={`${styles.navbarCenter} ${
          scroll > 110 ? styles.scrolled : ""
        }`}
      >
        <Image src={logo} placeholder = {"blur"} alt="" width={64} />
      </div>
      <div className={styles.navbarRight}>
        <ul className={styles.navbarList}>
          <li onClick={() => scrollToSection("subscription-section")}>
            Subscriptions
          </li>
          <li onClick={() => scrollToSection("contactus-section")}>
            Contact Us
          </li>
          <li
            className={styles.shop}
            onClick={() => {
              router.push("/shop");
            }}
          >
            Shop
          </li>
          <li onClick={() => setShowDropdown((prev) => !prev)}>
            <PiListBold style={{ fontSize: "30px" }} />
          </li>
        </ul>
      </div>
      <ul
        className={`${styles.navbarListDropdown} ${
          showDropdown ? styles.active : ""
        }`}
      >
        <li onClick={() => scrollToSection("home-section")}>Home</li>
        <li onClick={() => scrollToSection("properties-section")}>
          Properties
        </li>
        <li onClick={() => scrollToSection("product-section")}>Product List</li>
        <li onClick={() => scrollToSection("subscription-section")}>
          Subscriptions
        </li>
        <li
          onClick={() => {
            router.push("/shop");
          }}
        >
          Shop
        </li>
        <button
          className={styles.dropdownCloseButton}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <IoClose />
        </button>
      </ul>
    </div>
  );
}
